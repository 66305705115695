import type { V2_HtmlMetaDescriptor, V2_MetaFunction } from "@vercel/remix";

export function title(title: string): V2_HtmlMetaDescriptor[] {
  return [
    { title },
    {
      property: "og:title",
      content: title,
    },
    {
      name: "twitter:title",
      content: title,
    },
  ];
}

export function description(description: string): V2_HtmlMetaDescriptor[] {
  return [
    { name: "description", content: description },
    { name: "og:description", content: description },
    { name: "twitter:description", content: description },
  ];
}

type Matches = { pathname: string }[];

export function image({
  matches,
  path,
}: {
  matches: Matches;
  path: string;
}): V2_HtmlMetaDescriptor[] {
  const imageUrl = `${getOrigin(matches)}${path}`;
  return [
    { property: "og:image", content: `${imageUrl}` },
    { property: "twitter:image", content: `${imageUrl}` },
  ];
}

export function url(matches: Matches, path?: string): V2_HtmlMetaDescriptor[] {
  if (!path) {
    const lastMatch = matches[matches.length - 1];
    if (lastMatch) {
      path = lastMatch.pathname;
    }
  }

  return [
    {
      property: "og:url",
      content: `${getOrigin(matches)}${path}`,
    },
  ];
}

export function twitter(): V2_HtmlMetaDescriptor[] {
  return [
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:site",
      content: "@triggerdotdev",
    },
    {
      name: "twitter:creator",
      content: "@triggerdotdev",
    },
  ];
}

function getOrigin(matches: Matches) {
  const root = matches.find((m) => m.pathname === "/");
  let origin = "";
  if (root) {
    if ("data" in root) {
      let data = root.data as any;
      origin = data.origin;
    }
  }
  return origin;
}
